//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  mixins: [CommonMixin],
  props: {
    prop_dates: {
      type: Array,
      default: function _default() {
        return new Array();
      }
    },
    prop_label: {
      type: String,
      default: "Date Range"
    },
    prop_id: {
      type: String,
      default: "date-range-picker"
    },
    pageLoading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    prop_dates: {
      deep: true,
      immediate: true,
      handler: function handler() {
        var dates = this.lodash.cloneDeep(this.dates);
        var props = this.lodash.cloneDeep(this.prop_dates);

        if (props && this.lodash.isArray(props) && props.length === 2) {
          if (this.lodash.isEqual(dates, props) === false) {
            this.dates = props;
          }
        }
      }
    },
    dates: {
      deep: true,
      immediate: true,
      handler: function handler() {
        this.$emit("input", this.dates);
        this.$emit("change", this.dates);

        if (this.dates.length > 1) {
          this.datePicker = false;
        }
      }
    }
  },
  data: function data() {
    return {
      dates: []
    };
  },
  name: "date-range-picker"
};