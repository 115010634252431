//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "CreateLeave",
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      half_day: false,
      leaveRadioGroup: null,
      start_date_menu: false,
      end_date_menu: false
    };
  }
};